// Parallax
 
 .parallax {
     height: 100vh;
     overflow-x: hidden;
     overflow-y: auto;
     -webkit-perspective: 300px;
     perspective: 300px;
     -webkit-perspective-origin-x: 100%;
     perspective-origin-x: 100%;
     padding: 0;
     margin: 0;
 }
 
 .parallax-section {
     position: relative;
     -webkit-transform-style: preserve-3d;
     transform-style: preserve-3d;
     height: auto;
 }
 
 .parallax-layer {
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     -webkit-transform-origin-x: 100%;
     transform-origin-x: 100%;
 }
 
 .parallax-layer-front {
     -webkit-transform: translateZ(90px) scale(.7);
     transform: translateZ(90px) scale(.7);
     z-index: 1;
 }
 
 .parallax-layer-base {
     -webkit-transform: translateZ(0);
     transform: translateZ(0);
     z-index: 4;
 } 
 
 .parallax-layer-back {
     -webkit-transform: translateZ(-200px) scale(2);
     transform: translateZ(-200px) scale(2);
     z-index: 3;
     margin-left: -10px;
 }
 
 .parallax-layer-deep {
     -webkit-transform: translateZ(-600px) scale(3);
     transform: translateZ(-600px) scale(3);
     z-index: 2;
 }
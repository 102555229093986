// GLOBAL STYLES
// Z-levels
.z-depth-0 {
    box-shadow: none !important;
}

.z-depth-1 {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.z-depth-1-half {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.z-depth-2 {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-3 {
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-4 {
    box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}

.z-depth-5 {
    box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}

.hoverable {
    transition: box-shadow .55s;
    box-shadow: 0;
}

.hoverable:hover {
    transition: box-shadow .45s;
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

// Normalize
a {
    color: $link-color;
    text-decoration: none;
    cursor: pointer;
    // Gets rid of tap active state
    -webkit-tap-highlight-color: transparent;
    &:hover,
    &:focus {
        text-decoration: none;
    }
}

ul {
    padding: 0;
    list-style-type: none;
    li {
        list-style-type: none;
    }
}

.jumbotron,
.card,
.tag,
.alert,
.nav .nav-link,
.navbar-toggler,
.navbar,
.breadcrumb,
.page-item:last-child .page-link,
.page-item:first-child .page-link,
.pagination-lg .page-item:last-child .page-link,
.pagination-lg .page-item:first-child .page-link,
.pagination-sm .page-item:first-child .page-link,
.pagination-sm .page-item:last-child .page-link,
.list-group .list-group-item,
.modal-content,
.tooltip-inner,
.popover,
.dropdown-menu,
.input-group-addon,
.file-custom,
.card .card-header,
.nav-tabs,
img {
    @include border-radius(2px);
}

.jumbotron,
.card,
.list-group,
.popover,
.navbar,
.dropdown-menu,
.tag,
.chip {
    @extend .z-depth-1;
}

.popover,
.input-group-addon,
.dropdown-menu,
.pagination .page-item .page-link {
    border: 0;
}

// Circle pagination
.pagination .active .page-link {
    border-radius: 2px;
    transition: all 0.2s linear;
    @extend.z-depth-1;
}

.pagination .page-link {
    transition: all 0.3s linear;
    &:hover {
        transition: all 0.3s linear;
    }
    &:focus {
        background-color: transparent;
    }
}

.pagination {
    &.pg-blue {
        .active .page-link {
            background-color: $primary-color;
        }
    }
    &.pg-red {
        .active .page-link {
            background-color: $danger-color;
        }
    }
    &.pg-teal {
        .active .page-link {
            background-color: $default-color;
        }
    }
    &.pg-darkgrey {
        .active .page-link {
            background-color: $special-color;
        }
    }
    &.pg-dark {
        .active .page-link {
            background-color: $elegant-color;
        }
    }
    &.pg-bluegrey {
        .active .page-link {
            background-color: #3F729B;
        }
    }
    &.pg-amber {
        .active .page-link {
            background-color: #ff6f00;
        }
    }
    &.pg-purple {
        .active .page-link {
            background-color: #5e35b1;
        }
    }
}

.pager {
    a,
    .disabled>a {
        color: #fff;
    }
    &.pg-blue {
        li a {
            background-color: $primary-color;
            &:focus {
                background-color: $primary-color;
                color: #fff;
            }
            &:hover {
                background-color: $primary-color;
                color: #fff;
            }
        }
    }
    &.pg-red {
        li a {
            background-color: $danger-color;
            &:focus {
                background-color: $danger-color;
                color: #fff;
            }
            &:hover {
                background-color: $danger-color;
                color: #fff;
            }
        }
    }
    &.pg-teal {
        li a {
            background-color: $default-color;
            &:focus {
                background-color: $default-color;
                color: #fff;
            }
            &:hover {
                background-color: $default-color;
                color: #fff;
            }
        }
    }
    &.pg-darkgrey {
        li a {
            background-color: $special-color;
            &:focus {
                background-color: $special-color;
                color: #fff;
            }
            &:hover {
                background-color: $special-color;
                color: #fff;
            }
        }
    }
    &.pg-dark {
        li a {
            background-color: $elegant-color;
            &:focus {
                background-color: $elegant-color;
                color: #fff;
            }
            &:hover {
                background-color: $elegant-color;
                color: #fff;
            }
        }
    }
    &.pg-bluegrey {
        li a {
            background-color: #3F729B;
            &:focus {
                background-color: #3F729B;
                color: #fff;
            }
            &:hover {
                background-color: #3F729B;
                color: #fff;
            }
        }
    }
    &.pg-amber {
        li a {
            background-color: #ff6f00;
            &:focus {
                background-color: #ff6f00;
                color: #fff;
            }
            &:hover {
                background-color: #ff6f00;
                color: #fff;
            }
        }
    }
    &.pg-purple {
        li a {
            background-color: #5e35b1;
            &:focus {
                background-color: #5e35b1;
                color: #fff;
            }
            &:hover {
                background-color: #5e35b1;
                color: #fff;
            }
        }
    }
}

.pager .disabled>a,
.pager .disabled>a:focus,
.pager .disabled>a:hover {
    background-color: inerhit!important;
}

// Modal footer buttons fix
.modal-footer .btn+ .btn {
    margin-bottom: 6px;
}

// Shifting bacground fix
body.modal-open {
    overflow: inherit;
    padding-right: 0 !important;
}

body {
    overflow: auto !important;
}

// Card columns fix - cut edges of the cards
.card-columns .card {
    margin: 2px;
}

// Parallax
.parallax {
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    padding-top: 3em;
    padding-bottom: 3em;
}

// Scrollspy free
.nav-pills {
    &.horizontal-spy {
        .nav-item {
            .active {
                border-bottom: 2px solid $mdb-color;
                border-left: none;
            }
            &:hover {
                background-color: transparent;
                color: $mdb-color;
                font-weight: 500;
                border-left: none;
            }
        }
    }
}

//Nav normalize
.nav-pills .nav-item.open .nav-link,
.nav-pills .nav-item.open .nav-link:focus,
.nav-pills .nav-item.open .nav-link:hover,
.nav-pills .nav-link.active,
.nav-pills .nav-link.active:focus,
.nav-pills .nav-link.active:hover {
    background-color: transparent;
    color: #000;
}

//Disabled cursor
.disabled {
    cursor: not-allowed!important;
}

//Video responsive
.video-fluid {
    height: auto;
    width: 100%;
}

.video-full {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
}

//Media improvement
.media {
    img {
        @extend .z-depth-1-half;
    }
}

.card-block {
    h5 {
        margin-bottom: 1rem;
        font-size: 1rem;
        font-weight: 400;
    }
    h3 {
        margin-bottom: 1rem;
        font-weight: 400;
    }
    p {
        margin-bottom: 1rem;
    }
}

.tooltip {
    .tooltip-inner {
        padding: 0.4rem 0.8rem;
        @extend .z-depth-1-half;
    }
}

// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

body {background:black}

/* TEMPLATE STYLES */
			
	html,
	body,
	.view {
		height: 100%;
	}
	/* Navigation*/

	body {background:white}
	
	.navbar {
		background-color: transparent;
	}

	nav#main {border-top-left-radius:0;border-top-right-radius:0;}
	
	.scrolling-navbar {
		-webkit-transition: background .5s ease-in-out, padding .5s ease-in-out;
		-moz-transition: background .5s ease-in-out, padding .5s ease-in-out;
		transition: background .5s ease-in-out, padding .5s ease-in-out;
	}

	.top-nav-collapse {
		background-color: #111;
	}

	footer.page-footer {
		background-color: #111;
		margin-top: 0rem;
		padding-top:0;
	}

	@media only screen and (max-width: 768px) {
		.navbar {
			background-color: #111;
		}
	}
	/*Call to action*/

	.flex-center {
		color: #fff;
	}

	.view {
		background: url("/img/bg.jpg")no-repeat center center fixed; 
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
	}
	/*Contact section*/

	#contact .fa {
		font-size: 2.5rem;
		margin-bottom: 1rem;
		color: #1C2331;
	}

	
.text-white {color:white!important;color:rgba(255,255,255,.8)!important}
.nowrap	{white-space:nowrap;}
.ib {display:inline-block;}
.prewrap {white-space:pre-wrap;}
.bigger {font-size:120%}
sup {font-size:82%!important;opacity:.6;}
	
//========================= MDBootstrap fixes==========================================
textarea.md-textarea {padding-top:1rem;padding-bottom:0; resize:inherit; min-height:10rem}
button, html [type="button"], [type="reset"], [type="submit"] {-webkit-appearance: none!important;}
.navbar-toggleable-xs.collapsing {opacity:0}
.navbar-toggleable-xs.collapse.in {clear:both;}
.navbar-toggleable-xs.collapse.in ul:first-child {padding-top:1rem}
.table-no-hover.table-hover tbody tr:hover {background:inherit}
.table-no-hover.table-hover.table-striped tbody tr:nth-child(odd):hover {background-color: rgba(0,0,0,0.05);}
footer.page-footer .footer-copyright {overflow:auto;line-height:1.5em;height:auto;padding:1rem .5rem;}
.no-icon.dropdown-toggle::after {display:none}
textarea.md-textarea {background-color:rgba(235, 235, 249, 0.5) !important}
.dropdown .dropdown-menu .dropdown-item:hover {color:#4285F4 !important}
table.table a {margin-left:0}
form div.md-form, form fieldset.form-group {margin-top:3em}

// ACCORDION
.accordion {
    .panel {
        padding: 1rem 1.5rem;
        border-bottom: 1px solid #eee;
    }
    .fa-angle-down {
    float: right;
}
}

// Collapsible body
.collapsible-body {
    display: none;
}  
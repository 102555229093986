/* MDB HOVER EFFECTS */

// MDB Hover effects
.view {
    overflow: hidden;
    position: relative;
    cursor: default;
    .mask,
    .content {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0
    }
    img, video {
        display: block;
        position: relative
    }
}

.full-bg-img {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
}

// Overlays
.overlay {
    &:hover .mask {
        opacity: 1; 
    }
    img, video {
        transition: all 0.2s linear;
    }
    .mask {
        opacity: 0;
        transition: all 0.4s ease-in-out;
    }
}

//Overlay patterns
.pattern-1 {
    background: url(../img/overlays/01.png);
}

.pattern-2 {
    background: url(../img/overlays/02.png);
}

.pattern-3 {
    background: url(../img/overlays/03.png);
}

.pattern-4 {
    background: url(../img/overlays/04.png);
}

.pattern-5 {
    background: url(../img/overlays/05.png);
}

.pattern-6 {
    background: url(../img/overlays/06.png);
}

.pattern-7 {
    background: url(../img/overlays/07.png);
}

.pattern-8 {
    background: url(../img/overlays/08.png);
}

.pattern-9 {
    background: url(../img/overlays/09.png);
}

// Overlay masks
// Light overlays
.hm-blue-light .mask,
.hm-blue-light .full-bg-img {
    background-color: rgba(3, 169, 244, 0.3);
}

.hm-red-light .mask,
.hm-red-light .full-bg-img {
    background-color: rgba(244, 67, 54, 0.3);
}

.hm-pink-light .mask,
.hm-pink-light .full-bg-img {
    background-color: rgba(233, 30, 99, 0.3);
}

.hm-purple-light .mask,
.hm-purple-light .full-bg-img {
    background-color: rgba(156, 39, 176, 0.3);
}

.hm-indigo-light .mask,
.hm-indigo-light .full-bg-img {
    background-color: rgba(63, 81, 181, 0.3);
}

.hm-cyan-light .mask,
.hm-cyan-light .full-bg-img {
    background-color: rgba(0, 188, 212, 0.3);
}

.hm-teal-light .mask,
.hm-teal-light .full-bg-img {
    background-color: rgba(0, 150, 136, 0.3);
}

.hm-green-light .mask,
.hm-green-light .full-bg-img {
    background-color: rgba(76, 175, 80, 0.3);
}

.hm-lime-light .mask,
.hm-lime-light .full-bg-img {
    background-color: rgba(205, 220, 57, 0.3);
}

.hm-yellow-light .mask,
.hm-yellow-light .full-bg-img {
    background-color: rgba(255, 235, 59, 0.3);
}

.hm-orange-light .mask,
.hm-orange-light .full-bg-img {
    background-color: rgba(255, 152, 0, 0.3);
}

.hm-brown-light .mask,
.hm-brown-light .full-bg-img {
    background-color: rgba(121, 85, 72, 0.3);
}

.hm-grey-light .mask,
.hm-grey-light .full-bg-img {
    background-color: rgba(158, 158, 158, 0.3);
}

.hm-bluegrey-light .mask,
.hm-bluegrey-light .full-bg-img {
    background-color: rgba(96, 125, 139, 0.3);
}

.hm-black-light .mask,
.hm-black-light .full-bg-img {
    background-color: rgba(0, 0, 0, 0.3);
}

.hm-stylish-light .mask,
.hm-stylish-light .full-bg-img {
    background-color: rgba(62, 69, 81, 0.3);
}

.hm-white-light .mask,
.hm-white-light .full-bg-img {
    background-color: rgba(255, 255, 255, 0.3);
}

// Strong overlays
.hm-blue-strong .mask,
.hm-blue-strong .full-bg-img {
    background-color: rgba(3, 169, 244, 0.7);
}

.hm-red-strong .mask,
.hm-red-strong .full-bg-img {
    background-color: rgba(244, 67, 54, 0.7);
}

.hm-pink-strong .mask,
.hm-pink-strong .full-bg-img {
    background-color: rgba(233, 30, 99, 0.7);
}

.hm-purple-strong .mask,
.hm-purple-strong .full-bg-img {
    background-color: rgba(156, 39, 176, 0.7);
}

.hm-indigo-strong .mask,
.hm-indigo-strong .full-bg-img {
    background-color: rgba(63, 81, 181, 0.7);
}

.hm-cyan-strong .mask,
.hm-cyan-strong .full-bg-img {
    background-color: rgba(0, 188, 212, 0.7);
}

.hm-teal-strong .mask,
.hm-teal-strong .full-bg-img {
    background-color: rgba(0, 150, 136, 0.7);
}

.hm-green-strong .mask,
.hm-green-strong .full-bg-img {
    background-color: rgba(76, 175, 80, 0.7);
}

.hm-lime-strong .mask,
.hm-lime-strong .full-bg-img {
    background-color: rgba(205, 220, 57, 0.7);
}

.hm-yellow-strong .mask,
.hm-yellow-strong .full-bg-img {
    background-color: rgba(255, 235, 59, 0.7);
}

.hm-orange-strong .mask,
.hm-orange-strong .full-bg-img {
    background-color: rgba(255, 152, 0, 0.7);
}

.hm-brown-strong .mask,
.hm-brown-strong .full-bg-img {
    background-color: rgba(121, 85, 72, 0.7);
}

.hm-grey-strong .mask,
.hm-grey-strong .full-bg-img {
    background-color: rgba(158, 158, 158, 0.7);
}

.hm-bluegrey-strong .mask,
.hm-bluegrey-strong .full-bg-img {
    background-color: rgba(96, 125, 139, 0.7);
}

.hm-black-strong .mask,
.hm-black-strong .full-bg-img {
    background-color: rgba(0, 0, 0, 0.7);
}

.hm-stylish-strong .mask,
.hm-stylish-strong .full-bg-img {
    background-color: rgba(62, 69, 81, 0.7);
}

.hm-white-strong .mask,
.hm-white-strong .full-bg-img {
    background-color: rgba(255, 255, 255, 0.7);
}

// Light overlays
.hm-blue-slight .mask,
.hm-blue-slight .full-bg-img {
    background-color: rgba(3, 169, 244, 0.1);
}

.hm-red-slight .mask,
.hm-red-slight .full-bg-img {
    background-color: rgba(244, 67, 54, 0.1);
}

.hm-pink-slight .mask,
.hm-pink-slight .full-bg-img {
    background-color: rgba(233, 30, 99, 0.1);
}

.hm-purple-slight .mask,
.hm-purple-slight .full-bg-img {
    background-color: rgba(156, 39, 176, 0.1);
}

.hm-indigo-slight .mask,
.hm-indigo-slight .full-bg-img {
    background-color: rgba(63, 81, 181, 0.1);
}

.hm-cyan-slight .mask,
.hm-cyan-slight .full-bg-img {
    background-color: rgba(0, 188, 212, 0.1);
}

.hm-teal-slight .mask,
.hm-teal-slight .full-bg-img {
    background-color: rgba(0, 150, 136, 0.1);
}

.hm-green-slight .mask,
.hm-green-slight .full-bg-img {
    background-color: rgba(76, 175, 80, 0.1);
}

.hm-lime-slight .mask,
.hm-lime-slight .full-bg-img {
    background-color: rgba(205, 220, 57, 0.1);
}

.hm-yellow-slight .mask,
.hm-yellow-slight .full-bg-img {
    background-color: rgba(255, 235, 59, 0.1);
}

.hm-orange-slight .mask,
.hm-orange-slight .full-bg-img {
    background-color: rgba(255, 152, 0, 0.1);
}

.hm-brown-slight .mask,
.hm-brown-slight .full-bg-img {
    background-color: rgba(121, 85, 72, 0.1);
}

.hm-grey-slight .mask,
.hm-grey-slight .full-bg-img {
    background-color: rgba(158, 158, 158, 0.1);
}

.hm-bluegrey-slight .mask,
.hm-bluegrey-slight .full-bg-img {
    background-color: rgba(96, 125, 139, 0.1);
}

.hm-black-slight .mask,
.hm-black-slight .full-bg-img {
    background-color: rgba(0, 0, 0, 0.1);
}

.hm-stylish-slight .mask,
.hm-stylish-slight .full-bg-img {
    background-color: rgba(62, 69, 81, 0.1);
}

.hm-white-slight .mask,
.hm-white-slight .full-bg-img {
    background-color: rgba(255, 255, 255, 0.1);
}

// Zoom
.hm-zoom img {
    transition: all 0.2s linear;
}

.hm-zoom:hover img {
    transform: scale(1.1);
}

.hm-zoom:hover .mask {
    opacity: 1;
}

.img-overlay {
    position:relative;
    &:after {
        content: '';
        display:block;
        position:absolute;
        height:100%;
        width:100%;
        top:0;
        left:0;
        bottom:0;
        right:0;
        background:rgba(0,0,0,.17);
        transition: background 0.4s;
    }
    &:hover {
        &:after {
            background:rgba(0,0,0,.35);
        }
    }
    &.light {
        &:after {
            background:rgba(255,255,255,.17);
        }
        &:hover {
            &:after {
                background:rgba(255,255,255,.35);
            }
        }
    }
    &.blue {
        &:after {
            background:rgba(0,145,234,.17);
        }
        &:hover {
            &:after {
                background:rgba(0,145,234,.35);
            }
        }
    }
    &.green {
        &:after {
            background:rgba(76,175,80,.17);
        }
        &:hover {
            &:after {
                background:rgba(76,175,80,.35);
            }
        }
    }
    &.red {
        &:after {
            background:rgba(213,0,0,.17);
        }
        &:hover {
            &:after {
                background:rgba(213,0,0,.35);
            }
        }
    }
}
// FORMS PRO
//Radio Buttons

/* Remove default Radio Buttons */

[type="radio"]:not(:checked),
[type="radio"]:checked {
    position: absolute;
    left: -9999px;
    visibility: hidden;
}

[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
    @include transition(.28s ease);
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    -ms-user-select: none;
    /* IE10+ */
}

[type="radio"] + label:before,
[type="radio"] + label:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 16px;
    height: 16px;
    z-index: 0;
    @include transition(.28s ease);
}


/* Unchecked styles */

[type="radio"]:not(:checked) + label:before {
    border-radius: 50%;
    border: 2px solid $radio-empty-color;
}

[type="radio"]:not(:checked) + label:after {
    border-radius: 50%;
    border: 2px solid $radio-empty-color;
    z-index: -1;
    @include transform(scale(0));
}


/* Checked styles */

[type="radio"]:checked + label:before {
    border-radius: 50%;
    border: 2px solid transparent;
}

[type="radio"]:checked + label:after {
    border-radius: 50%;
    border: 2px solid $radio-fill-color;
    background-color: $radio-fill-color;
    z-index: 0;
    @include transform(scale(1.02));
}


/* Radio With gap */

[type="radio"].with-gap:checked + label:before {
    border-radius: 50%;
    border: 2px solid $radio-fill-color;
}

[type="radio"].with-gap:checked + label:after {
    border-radius: 50%;
    border: 2px solid $radio-fill-color;
    background-color: $radio-fill-color;
    z-index: 0;
    @include transform(scale(.5));
}


/* Disabled Radio With gap */

[type="radio"].with-gap:disabled:checked + label:before {
    border: 2px solid $input-disabled-color;
}

[type="radio"].with-gap:disabled:checked + label:after {
    border: none;
    background-color: $input-disabled-color;
}


/* Disabled style */

[type="radio"]:disabled:not(:checked) + label:before,
[type="radio"]:disabled:checked + label:before {
    background-color: transparent;
    border-color: $input-disabled-color;
}

[type="radio"]:disabled + label {
    color: $input-disabled-color;
}

[type="radio"]:disabled:not(:checked) + label:before {
    border-color: $input-disabled-color;
}

[type="radio"]:disabled:checked + label:after {
    background-color: $input-disabled-color;
    border-color: $input-disabled-solid-color;
}


/***************
   Checkboxes
***************/


/* Remove default checkbox */

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
    visibility: hidden;
}

// checkbox Styles
[type="checkbox"] {
    // Text Label Style
    + label {
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        display: inline-block;
        height: 25px;
        line-height: 25px;
        font-size: 1rem;
        -webkit-user-select: none;
        /* webkit (safari, chrome) browsers */
        -moz-user-select: none;
        /* mozilla browsers */
        -khtml-user-select: none;
        /* webkit (konqueror) browsers */
        -ms-user-select: none;
        /* IE10+ */
    }
    /* checkbox aspect */
    + label:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        z-index: 0;
        border: 2px solid $radio-empty-color;
        border-radius: 1px;
        margin-top: 2px;
        @include transition(.2s);
    }
    &:not(:checked):disabled + label:before {
        border: none;
        background-color: $input-disabled-color;
    }
}

[type="checkbox"]:checked {
    + label:before {
        top: -4px;
        left: -3px;
        width: 12px;
        height: 22px;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid $radio-fill-color;
        border-bottom: 2px solid $radio-fill-color;
        @include transform(rotate(40deg));
        -webkit-backface-visibility: hidden;
        @include transform-origin(100% 100%);
    }
    &:disabled + label:before {
        border-right: 2px solid $input-disabled-color;
        border-bottom: 2px solid $input-disabled-color;
    }
}


/* Indeterminate checkbox */

[type="checkbox"]:indeterminate {
    +label:before {
        left: -10px;
        top: -11px;
        width: 10px;
        height: 22px;
        border-top: none;
        border-left: none;
        border-right: 2px solid $radio-fill-color;
        border-bottom: none;
        @include transform(rotate(90deg));
        -webkit-backface-visibility: hidden;
        @include transform-origin(100% 100%);
    }
    // Disabled indeterminate
    &:disabled + label:before {
        border-right: 2px solid $input-disabled-color;
        background-color: transparent;
    }
}

// FILLED IN STYLES //
// Filled in Default
[type="checkbox"].filled-in {
    // General
    + label:after {
        border-radius: 2px;
    }
    + label:before,
    + label:after {
        content: '';
        left: 0;
        position: absolute;
        /* .1s delay is for check animation */
        transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
        z-index: 1;
    }
    // Unchecked style
    &:not(:checked) + label:before {
        width: 0;
        height: 0;
        border: 3px solid transparent;
        left: 6px;
        top: 10px;
        -webkit-transform: rotateZ(37deg);
        transform: rotateZ(37deg);
        -webkit-transform-origin: 20% 40%;
        transform-origin: 100% 100%;
    }
    &:not(:checked) + label:after {
        height: 20px;
        width: 20px;
        background-color: transparent;
        border: 2px solid $radio-empty-color;
        top: 0px;
        z-index: 0;
    }
    // Checked style
    &:checked {
        + label:before {
            top: 0;
            left: 1px;
            width: 8px;
            height: 13px;
            border-top: 2px solid transparent;
            border-left: 2px solid transparent;
            border-right: 2px solid $input-bg-color;
            border-bottom: 2px solid $input-bg-color;
            -webkit-transform: rotateZ(37deg);
            transform: rotateZ(37deg);
            -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
        }
        + label:after {
            top: 0px;
            width: 20px;
            height: 20px;
            border: 2px solid $secondary-color;
            background-color: $secondary-color;
            z-index: 0;
        }
    }
    // Disabled style
    &:disabled:not(:checked) + label:before {
        background-color: transparent;
        border: 2px solid transparent;
    }
    &:disabled:not(:checked) + label:after {
        border-color: transparent;
        background-color: $input-disabled-solid-color;
    }
    &:disabled:checked + label:before {
        background-color: transparent;
    }
    &:disabled:checked + label:after {
        background-color: $input-disabled-solid-color;
        border-color: $input-disabled-solid-color;
    }
}

// Filled in dANGER
[type="checkbox"].filled-in-danger {
    // General
    + label:after {
        border-radius: 2px;
    }
    + label:before,
    + label:after {
        content: '';
        left: 0;
        position: absolute;
        /* .1s delay is for check animation */
        transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
        z-index: 1;
    }
    // Unchecked style
    &:not(:checked) + label:before {
        width: 0;
        height: 0;
        border: 3px solid transparent;
        left: 6px;
        top: 10px;
        -webkit-transform: rotateZ(37deg);
        transform: rotateZ(37deg);
        -webkit-transform-origin: 20% 40%;
        transform-origin: 100% 100%;
    }
    &:not(:checked) + label:after {
        height: 20px;
        width: 20px;
        background-color: transparent;
        border: 2px solid $radio-empty-color;
        top: 0px;
        z-index: 0;
    }
    // Checked style
    &:checked {
        + label:before {
            top: 0;
            left: 1px;
            width: 8px;
            height: 13px;
            border-top: 2px solid transparent;
            border-left: 2px solid transparent;
            border-right: 2px solid $input-bg-color;
            border-bottom: 2px solid $input-bg-color;
            -webkit-transform: rotateZ(37deg);
            transform: rotateZ(37deg);
            -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
        }
        + label:after {
            top: 0px;
            width: 20px;
            height: 20px;
            border: 2px solid transparent;
            background-color: red;
            z-index: 0;
        }
    }
}


/***************
  Select Field
***************/


.select-label {
    position: absolute;
}

.select-wrapper {
    position: relative;
    input.select-dropdown {
        position: relative;
        cursor: pointer;
        // color: #444;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $input-border-color;
        outline: none;
        height: 3rem;
        line-height: 3rem;
        width: 100%;
        font-size: 1rem;
        margin: 0 0 15px 0;
        padding: 0;
        display: block;

        -webkit-user-select:none;
        -moz-user-select:none;
        -ms-user-select:none;
        -o-user-select:none;
        user-select:none;

    }
    .select-dropdown {
        -webkit-user-select:none;
        -moz-user-select:none;
        -ms-user-select:none;
        -o-user-select:none;
        user-select:none;
    }
    span.caret {
        color: initial;
        position: absolute;
        right: 0;
        top: 16px;
        font-size: 10px;
        &.disabled {
            color: $input-disabled-color;
        }
    }
    & + label {
        position: absolute;
        top: -14px;
        font-size: $label-font-size;
    }
}

select {
    display: none;
}

select.browser-default {
    display: block;
}

// Disabled styles
select:disabled {
    color: rgba(0, 0, 0, .3);
}

.select-wrapper input.select-dropdown:disabled {
    color: rgba(0, 0, 0, .3);
    cursor: default;
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -ms-user-select: none;
    /* IE10+ */
    border-bottom: 1px solid rgba(0, 0, 0, .3);
}

.select-wrapper i {
    color: rgba(0, 0, 0, .3);
}

.select-dropdown li.disabled,
.select-dropdown li.disabled > span,
.select-dropdown li.optgroup {
    color: rgba(0, 0, 0, .3);
    background-color: transparent!important;
    cursor: context-menu;
}

// Icons
.select-dropdown li {
    img {
        height: $dropdown-item-height - 10;
        width: $dropdown-item-height - 10;
        margin: 5px 15px;
        float: right;
    }
}

// Optgroup styles
.select-dropdown li.optgroup {
    border-top: 1px solid $dropdown-hover-bg-color;
    &.selected > span {
        color: rgba(0, 0, 0, .7);
    }
    & > span {
        color: rgba(0, 0, 0, .4);
    }
    & ~ li:not(.optgroup) {
        padding-left: 1rem;
    }
}

.multiple-select-dropdown li [type="checkbox"] + label {
    height: 10px;
}

select {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/***************
     Switch 
***************/

.switch,
.switch * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
}

.switch label {
    cursor: pointer;
}

.switch label input[type=checkbox] {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch label input[type=checkbox]:checked + .lever {
    background-color: $switch-checked-lever-bg;
}

.switch label input[type=checkbox]:checked + .lever:after {
    background-color: $switch-bg-color;
}

.switch label .lever {
    content: "";
    display: inline-block;
    position: relative;
    width: 40px;
    height: 15px;
    background-color: $switch-unchecked-lever-bg;
    border-radius: 15px;
    margin-right: 10px;
    transition: background 0.3s ease;
    vertical-align: middle;
    margin: 0 16px;
}

.switch label .lever:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 21px;
    height: 21px;
    background-color: $switch-unchecked-bg;
    border-radius: 21px;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .4);
    left: -5px;
    top: -3px;
    transition: left 0.3s ease, background .3s ease, box-shadow 0.1s ease;
}

// Switch active style
input[type=checkbox]:checked:not(:disabled) ~ .lever:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .4), 0 0 0 15px transparentize($switch-bg-color, .9);
}

input[type=checkbox]:not(:disabled) ~ .lever:active:after {
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, .4), 0 0 0 15px rgba(0, 0, 0, .08);
}

.switch label input[type=checkbox]:checked + .lever:after {
    left: 24px;
}

// Disabled Styles
.switch input[type=checkbox][disabled] + .lever {
    cursor: default;
}

.switch label input[type=checkbox][disabled] + .lever:after,
.switch label input[type=checkbox][disabled]:checked + .lever:after {
    background-color: $input-disabled-solid-color;
}


/*********************
      File Input
**********************/

.file-field {
    position: relative;
    .file-path-wrapper {
        overflow: hidden;
        padding-left: 10px;
        height: 2.5rem;
    }
    input.file-path {
        width: 100%;
        height: 2.1rem;
    }
    .btn {
        float: left;
        line-height: 3rem;
    }
    span {
        cursor: pointer;
    }
    input[type=file] {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        font-size: 1px;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
    }
}

.btn-file {
    padding-top: 1px;
}


/***************
      Range
***************/

.range-field {
    position: relative;
}

input[type=range],
input[type=range] + .thumb {
    cursor: pointer;
}

input[type=range] {
    position: relative;
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    margin: 15px 0px;
    padding: 0;
}

input[type=range] + .thumb {
    position: absolute;
    border: none;
    height: 0;
    width: 0;
    border-radius: 50%;
    background-color: $radio-fill-color;
    top: 10px;
    margin-left: -6px;
    @include transform-origin(50% 50%);
    @include transform(rotate(-45deg));
    .value {
        display: block;
        width: 30px;
        text-align: center;
        color: $radio-fill-color;
        font-size: 0;
        @include transform(rotate(45deg));
    }
    &.active {
        border-radius: 50% 50% 50% 0;
        .value {
            color: $input-bg-color;
            margin-left: -1px;
            margin-top: 8px;
            font-size: 10px;
        }
    }
}

input[type=range]:focus {
    outline: none;
}

// WebKit
input[type=range] {
    -webkit-appearance: none;
}

input[type=range]::-webkit-slider-runnable-track {
    height: 3px;
    background: #c2c0c2;
    border: none;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: $radio-fill-color;
    transform-origin: 50% 50%;
    margin: -5px 0 0 0;
    @include transition(.3s);
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ccc;
}

// FireFox
input[type=range] {
    /* fix for FF unable to apply focus style bug  */
    border: 1px solid white;
    /*required for proper track sizing in FF*/
}

input[type=range]::-moz-range-track {
    height: 3px;
    background: #ddd;
    border: none;
}

input[type=range]::-moz-range-thumb {
    border: none;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background: $radio-fill-color;
    margin-top: -5px;
}


/*hide the outline behind the border*/

input[type=range]:-moz-focusring {
    outline: 1px solid white;
    outline-offset: -1px;
}

input[type=range]:focus::-moz-range-track {
    background: #ccc;
}

// IE 10+
input[type=range]::-ms-track {
    height: 3px;
    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;
    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;
    /*remove default tick marks*/
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: #777;
}

input[type=range]::-ms-fill-upper {
    background: #ddd;
}

input[type=range]::-ms-thumb {
    border: none;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background: $radio-fill-color;
}

input[type=range]:focus::-ms-fill-lower {
    background: #888;
}

input[type=range]:focus::-ms-fill-upper {
    background: #ccc;
}

// IE 10+
input[type=range]::-ms-track {
    height: 3px;
    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;
    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;
    /*remove default tick marks*/
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: #777;
}

input[type=range]::-ms-fill-upper {
    background: #ddd;
}

input[type=range]::-ms-thumb {
    border: none;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background: $radio-fill-color;
}

input[type=range]:focus::-ms-fill-lower {
    background: #888;
}

input[type=range]:focus::-ms-fill-upper {
    background: #ccc;
}

.input-group {
    .input-group-addon {
        background-color: #e0e0e0;
        [type="checkbox"] + label {
            padding-left: 18px;
            height: 13px;
        }
        [type="radio"] + label {
            padding-left: 24px;
            height: 13px;
        }
    }
    // Style Placeholders
    ::-webkit-input-placeholder {
        padding-left: 1rem;
    }
    :-moz-placeholder {
        /* Firefox 18- */
        padding-left: 1rem;
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        padding-left: 1rem;
    }
    :-ms-input-placeholder {
        padding-left: 1rem;
    }
    span {
        z-index: 10;
    }
    .form-control {
        margin-top: 3px;
    }
}
// CAROUSELS BASIC
.carousel-control:hover {
    @include transition-duration($duration: 400ms);
}

.carousel-fade .carousel-inner {
    .carousel-item {
        opacity: 0;
        transition-property: opacity;
    }
    .active {
        opacity: 1;
    }
    .active.left,
    .active.right {
        left: 0;
        opacity: 0;
        z-index: 1;
    }
    .next.left,
    .prev.right {
        opacity: 1;
    }
}

.carousel-fade .carousel-control {
    z-index: 2;
}

@media all and (transform-3d),
(-webkit-transform-3d) {
    .carousel-fade .carousel-inner > .carousel-item.next,
    .carousel-fade .carousel-inner > .carousel-item.active.right {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .carousel-item.prev,
    .carousel-fade .carousel-inner > .carousel-item.active.left {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .carousel-item.next.left,
    .carousel-fade .carousel-inner > .carousel-item.prev.right,
    .carousel-fade .carousel-inner > .carousel-item.active {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}
// BLOG COMPONENTS
// Author box
.author-box {
    @extend .jumbotron;
    padding: 3rem;
}

@media (max-width: 772px) {
    .author-box {
        text-align: center;
        img {
            margin-bottom: 2rem;
            display: inline;
        }
    }
}

// Comments section
.comments-list,
.reply-form {
    margin-bottom: 3rem;
    .tag {
        @extend .z-depth-1-half;
    }
    img {
        max-width: 100px;
        @extend .z-depth-2;
        @extend .img-fluid;
        @include border-radius(50%);
    }
    .row {
        margin-bottom: 2rem;
    }
    .user-name {
        color: #000;
    }
    .comment-date {
        color: #666;
    }
    .comment-text {
        color: #9e9e9e;
    }
    h4 {
        text-align: center;
    }
    @media (max-width: 543px) {
        text-align: center;
        img {
            display: inline;
            margin-bottom: 1rem;
        }
        .md-form {
            text-align: left;
        }
    }
}

// Full width blog section
.section-blog-fw {
    .view {
        @extend .z-depth-1;
    }
    .jumbotron {
        @extend .m-1;
        text-align: center;
    }
    h2 {
        @extend .h2-responsive;
        margin-bottom: 1rem;
        a {
            color: #424242;
            @include transition (0.2s);
            &:hover {
                color: #616161;
                @include transition (0.2s);
            }
        }
    }
    .excerpt, .post-text {
        margin-left: 10%;
        margin-right: 10%;
        text-align: justify;
        @media (max-width: 992px) {
            margin-left: 5%;
            margin-right: 5%;
        }
    }
    hr {
        display: block;
    }
}